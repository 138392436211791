<template lang="pug">
  .c-actions
    slot(name="prepend")
    hold(:activeItems="activeItems" @change="$emit('click:hold', $event)").c-actions-item
    acceptBulk(:activeItems="activeItems").c-actions-item
    emailBulk(:activeItems="activeItems").c-actions-item
    bulk-assign-labels(:disabled="!hasActiveItems" :activeItems="activeItems").c-actions-item
    bulk-assign-labels(btnText="Unassign labels" mode="remove" :disabled="!hasActiveItems" :activeItems="activeItems").c-actions-item
    handleFailedCandidatesProvider(v-slot="handleFailed")
      v-dialog(:value="handleFailed.showing" @input="handleFailed.toggle" scrollable)
        handleFailedBody(
          :list="handleFailed.failedCandidates"
          :error="handleFailed.error"
          :totalSendCount="handleFailed.totalSendCount"
          @input="handleFailed.toggle"
        )

    handleFailedCandidatesProvider(v-slot="handleFailed")
      mijn-bulk(
        v-if="isTasksPage"
        :disabled="!hasActiveItems"
        :activeItems="activeItems"
        @send="handleFailed.check($event, hasActiveItems)"
      ).c-actions-item

    handleFailedCandidatesProvider(v-slot="handleFailed")
      exchange(
        :disabled="!hasActiveItems"
        :activeItems="activeItems"
        @send="handleFailed.check($event, hasActiveItems)"
      ).c-actions-item

    handleFailedCandidatesProvider(v-slot="handleFailed")
      exchange(
        v-if="isAllPage"
        :disabled="!hasActiveItems"
        :activeItems="activeItems"
        @send="handleFailed.check($event, hasActiveItems)"
        title="Suggest exchange"
        isExchange
      ).c-actions-item

    handleFailedCandidatesProvider(v-slot="handleFailed")
      addRequests.c-actions-item(
        :disabled="!hasActiveItems || !hasLicenseTypeFilters"
        :activeItems="activeItems"
        :showLicensesTooltip="!hasLicenseTypeFilters"
        :license-types="licenseTypeFilters"
        @send="handleFailed.check($event, hasActiveItems)"
      )

    removeRequests(:disabled="!hasActiveItems" :activeItems="activeItems").c-actions-item
    tryToReserve(:activeItems="activeItems" :disabled="!hasActiveItems").c-actions-item
    removeFromAcceptance(:disabled="!hasActiveItems" :activeItems="activeItems").c-actions-item
    sync(:disabled="!hasActiveItems" :activeItems="activeItems").c-actions-item
    copy(:activeItems="activeItems" :disabled="!hasActiveItems" :list="list" multiple).c-actions-item
    candidate-color(:activeItems="activeItems" :disabled="!hasActiveItems").c-actions-item
    slot(name="append")
</template>

<script>
import ROUTE_NAMES from "@/app/admin/router/router-const";

export default {
  name: 'CandidatesActions',

  props: {
    activeItems: Array,
    list: Array
  },

  computed: {
    hasActiveItems() {
      return !!(this.activeItems && this.activeItems.length)
    },

    isTasksPage() {
      return this.$route.name === ROUTE_NAMES.CANDIDATES_TASKS
    },

    isAllPage() {
      return this.$route.name === ROUTE_NAMES.CANDIDATES_ALL
    },

    licenseTypeFilters() {
      const licenseTypeFilters = this.$route.query.license_type;
      return (Array.isArray(licenseTypeFilters) ? licenseTypeFilters : [licenseTypeFilters]).filter(Boolean);
    },

    hasLicenseTypeFilters() {
      return this.licenseTypeFilters.length > 0;
    }
  },

  components: {
    hold: () => import('./components/CandidateHold.vue'),
    acceptBulk: () => import('./components/CandidateAcceptActivatorBulk.vue'),
    emailBulk: () => import('./components/CandidatesEmail.vue'),
    bulkAssignLabels: () => import('./components/candidates/CandidatesBulkAssignLabels.vue'),
    handleFailedCandidatesProvider: () => import('./components/CandidatesHandleFailedProvider'),
    handleFailedBody: () => import('./components/CandidatesHandleFailedBody.vue'),
    exchange: () => import('./components/CandidatesExchange.vue'),
    addRequests: () => import('./components/CandidatesAddExamRequest.vue'),
    removeRequests: () => import('./components/CandidatesRemoveExamRequest.vue'),
    tryToReserve: () => import('./components/CandidatesTryToReserve.vue'),
    removeFromAcceptance: () => import('./components/CandidatesRemoveFromAcceptance.vue'),
    sync: () => import('./components/CandidatesSync.vue'),
    copy: () => import('./components/candidates/CandidatesCopy.vue'),
    candidateColor: () => import('./components/CandidateColor.vue'),
    mijnBulk: () => import('./components/CandidatesMIJNBulk.vue'),
  }
}
</script>

<style lang="scss">
.c-actions {
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;

  @include media("<=tablet") {
    flex-wrap: initial;
  }

  &__block {
    display: flex;
  }

  &-item {
    margin-bottom: 4px;
    margin-right: 8px;
  }
}
</style>